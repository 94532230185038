import React, { useState, useEffect } from "react";

// CSV Parser
// import Papa from "papaparse";

import ParticlesBg from "particles-bg";
// import randomIcon from '../Utils/randomIcon';
import chiliPepper from "../assets/icons/ChiliPepper.svg";

// Animation
import { Spring } from "react-spring/renderprops";

export default function MillionMealMarchHeader() {
  let [mealCount, setMealCount] = useState(55000);
  let [activeMealGoal, setActiveMealGoal] = useState(100000);

  let barPercentage = (mealCount / activeMealGoal) * 100;
  // console.log("barPercentage: ", barPercentage.toFixed());
  // console.log("mealCount: ", mealCount);

  useEffect(() => {
    // async function fetchMealCounterCSV() {
    //   // const storageResult = await Storage.get('meal_counter.csv', { download: true });
    //   // console.log('storageResult: ', storageResult)
    //   let meal_counter_csv_url =
    //     "https://rasta-tease-assets-bucket.s3.amazonaws.com/meal_counter.csv";
    //   return Papa.parse(meal_counter_csv_url, {
    //     download: true,
    //     header: true,
    //     // rest of config ...
    //     complete: async function (results) {
    //       // console.log("Finished:", results.data);
    //       // Set Meal Count with highet Epoch
    //       let totMealCount = await findMealCount(results.data);
    //       setMealCount(Number(totMealCount));
    //       // Set Meal Count Goal
    //       let mealGoal = findActiveMealGoal(totMealCount);
    //       setActiveMealGoal(mealGoal);
    //     },
    //     // Handle Error
    //     error: (err) => {
    //       console.log("Error fetching csv URL: ", err);
    //     },
    //   });
    // }
    // fetchMealCounterCSV();
  }, []);

  // Determine the active meal goal
  // function findActiveMealGoal(currentTotal) {
  //   currentTotal = Number(currentTotal);
  //   let mealGoal;
  //   if (currentTotal < 10000) {
  //     mealGoal = 10000;
  //   } else if (currentTotal >= 10000 && currentTotal < 50000) {
  //     mealGoal = 50000;
  //   } else if (currentTotal >= 50000 && currentTotal < 100000) {
  //     mealGoal = 100000;
  //   } else if (currentTotal >= 100000 && currentTotal < 250000) {
  //     mealGoal = 250000;
  //   } else if (currentTotal >= 250000 && currentTotal < 500000) {
  //     mealGoal = 500000;
  //   } else if (currentTotal >= 500000 && currentTotal < 750000) {
  //     mealGoal = 750000;
  //   } else if (currentTotal >= 750000 && currentTotal < 1000000) {
  //     mealGoal = 1000000;
  //   } else {
  //     mealGoal = 1000000;
  //   }
  //   return mealGoal;
  // }

  // Finds the most recent csv file update and returns the current total meals
  // function findMealCount(mealUpdateArr) {
  //   if (mealUpdateArr) {
  //     let recentMealCount = {
  //       Date_Epoch: "0",
  //       Meal_Change: "0",
  //       Total_Meals: "0",
  //     };

  //     for (let i = 0; i < mealUpdateArr.length; i++) {
  //       if (mealUpdateArr[i]["Date_Epoch"] > recentMealCount["Date_Epoch"]) {
  //         recentMealCount = mealUpdateArr[i];
  //       }
  //     }

  //     return recentMealCount["Total_Meals"];
  //   }
  // }

  // Particle Config
  let config = {
    num: [2, 5],
    rps: 0.1,
    radius: [5, 40],
    life: [1.5, 3],
    v: [2, 3],
    tha: [-50, 50],
    alpha: [0.6, 0],
    scale: [0.1, 2.9],
    body: chiliPepper,
    // body: () => randomIcon(),
    position: "all",
    // color: ["random", "#ff0000"],
    cross: "dead",
    random: 10,
  };

  // config = Object.assign(config, {
  //   body: randomIcon(),
  // })

  return (
    <div>
      <section
        id="mmm-section"
        className="highlight-phone"
        style={styles.mmmSectionElem}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="intro" style={styles.mmmIntroContainer}>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  config={{ duration: 1500, delay: 500 }}
                >
                  {(props) => (
                    <div style={props}>
                      <h1 style={styles.mmmIntroHeading}>Million Meal March</h1>
                      <p>
                        Support Rasta Tease's initiative to provide 1 Million
                        meals by 2024 for our brothers and sisters in
                        need.&nbsp;Each purchase will yield up to 10 meals for
                        the disenfranchised with our partners Union Gospel
                        Mission - Twin Cities and Feeding America.
                      </p>
                      <a
                        className="btn btn-primary"
                        role="button"
                        href="#product-section"
                        style={styles.mmmIntroButton}
                      >
                        Take the first step
                      </a>
                    </div>
                  )}
                </Spring>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div id="meal-counter" style={styles.mealCounterContainer}>
                <div>
                  <Spring
                    from={{ number: 0 }}
                    to={{ number: mealCount }}
                    config={{ duration: 2000, delay: 1000 }}
                  >
                    {(props) => (
                      <div style={props}>
                        <h1 style={styles.mealCounterCount}>
                          {props.number.toFixed()}
                        </h1>
                      </div>
                    )}
                  </Spring>
                </div>
                <h2 style={styles.mealCounterTitle}>Meals Donated</h2>
                <div style={styles.progressBarContainer}>
                  <Spring
                    from={{ number: 0 }}
                    to={{ number: barPercentage }}
                    config={{ duration: 2000, delay: 1000 }}
                  >
                    {(props) => (
                      <div style={props}>
                        <div
                          className="progress"
                          style={styles.progressBarElem}
                        >
                          <div
                            className="progress-bar bg-danger"
                            aria-valuenow={props.number.toFixed()}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{
                              fontWeight: 300,
                              fontSize: "30px",
                              width: `${props.number.toFixed()}%`,
                            }} /*style={styles.progressBarElemFill}*/
                          >
                            {/* {console.log(
                              "props.number: ",
                              props.number.toFixed()
                            )} */}
                            {props.number.toFixed()} %
                          </div>
                        </div>
                      </div>
                    )}
                  </Spring>
                  <h3
                    className="text-center"
                    style={styles.progressBarSubtitle}
                  >
                    Next Milestone: {activeMealGoal} Meals
                  </h3>
                  {/* <h6 className="text-center" style={styles.progressBarSubtitle}>Total Goal: 1,000,000 Meals</h6> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ParticlesBg type="custom" config={config} bg={true} />
    </div>
  );
}

const styles = {
  mmmSectionElem: {
    background: "rgba(255,255,2555,0.75)",
  },
  mmmIntroContainer: {
    paddingBottom: "30px",
    textAlign: "center",
  },
  mmmIntroHeading: {
    textAlign: "center",
    color: "var(--bs-red)",
    fontSize: "max(5vw, 40px)",
    // fontSize: 'max(24px,3vw)',
    fontWeight: 800,
    textTransform: "uppercase",
  },
  mmmIntroButton: {
    background: "var(--bs-red)",
    textAlign: "center",
  },
  mealCounterContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justify: "space-between",
  },
  mealCounterCount: {
    textAlign: "center",
    fontSize: "121.88px",
    marginBottom: "0px",
    fontWeight: "bold",
    color: "var(--bs-red)",
  },
  mealCounterTitle: {
    textAlign: "center",
    marginBottom: "0px",
    color: "var(--bs-red)",
  },
  progressBarContainer: {
    padding: "10px",
    marginTop: "50px",
  },
  progressBarSubtitle: {
    borderColor: "var(--bs-red)",
    color: "rgb(0,0,0)",
  },
  progressBarElem: {
    background: "var(--bs-dark)",
    borderStyle: "solid",
    height: "50px",
    borderRadius: "25px",
  },
  progressBarElemFill: {
    // color: 'var(--bs-red)',
    fontWeight: 300,
    fontSize: "30px",
    width: "22%",
  },
};
