import React from 'react';

// Animation
import { Spring } from 'react-spring/renderprops';

export default function FoodStory() {
  return (
    <div>
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {props => (
          <div style={props}>
            <section id="story-section">
              <div className="container">
                <div className="row" style={styles.foodRow}>
                  <div className="col" style={styles.foodHeaderCol}>
                    <div>
                      <h1 style={styles.foodHeaderHeading}>Get Real Food</h1>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div>
                      <img className="rounded-circle img-fluid" src="assets/img/Peewee_Con_Peppers_habanero.png" style={styles.peppersConPeeweeImage} alt="Peewee Dread holding peppers" />
                    </div>
                  </div>
                  <div className="col" style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <p style={styles.foodStoryBody}>
                        <strong>
                          We use only farm fresh organic ingredients sourced locally. We are not only vegan but also gluten free and all of our ingredients grow naturally from the Earth.
                    <br />
                          <br />
                    Get RASTA TEASE in your fridge. We promise you'll know exactly what you're eating.
                  </strong>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )
        }
      </Spring>
    </div>
  )
}

const styles = {
  foodRow: {
    marginBottom: '20px',
    marginRight: '0px',
    marginLeft: '0px',
  },
  foodHeaderCol: {
    minWidth: '90%',
    // width: '100%',
    // maxWidth: '90vw',
    backgroundColor: 'var(--bs-red)',
    marginTop: '30px',
    marginBottom: '30px',
    padding: '10px',

  },
  foodHeaderHeading: {
    color: 'var(--bs-light)',
    width: '100%',
    textAlign: 'center',
    fontSize: 'max(40px,5wv)',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  peppersConPeeweeImage: {
    maxWidth: '100%',
    minWidth: '300px',
  },
  foodStoryBody: {
    textAlign: 'justify',
    fontSize: 'max(24px,3vw)',
    lineHeight: '1em',
  }
}