import React from 'react'

export default function NavBar() {
  return (
    <div>
      <nav className="navbar navbar-light navbar-expand-md navigation-clean-search" style={styles.navElem}>
        <div className="container">
          <a className="navbar-brand" id="nav-brand-elem" href="/" style={styles.navBrandContainer}>
            <div>
              <img id="rt-nav-logo" src="assets/img/rt_banner.png" style={styles.navBrandImage} alt="Rasta Tease" />
            </div>
          </a>
          {/* <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1">
            <span className="visually-hidden">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className=" d-md-flex justify-content-md-end" id="navcol-1"> {/*Missing className names for toggler btn: collapse navbar-collapse*/}
            <a className="btn btn-light action-button" role="button" href="#product-section" style={styles.navBuyButton}>Buy Now</a>
          </div>
        </div>
      </nav>
    </div>
  )
}


const styles = {
  navElem: {
    // background: 'rgba(255,255,255,0)',
    background: 'rgba(255,255,2555,0.75)',
    paddingBottom: 0,
    paddingTop: 0,
    marginBottom: '0px'
  },
  navBrandContainer: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  navBrandImage: {
    textAlign: 'center',
  },
  navBuyButton: {
    background: 'var(--bs-red)',
  }
}