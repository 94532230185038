import React from 'react';

// Animation
import { Spring } from 'react-spring/renderprops';

export default function MillionMealMarchMission() {
  return (
    <div>
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {props => (
          <div style={props}>
            <section id="mission-section">
              <div className="container" style={styles.missionContainer}>
                <div className="row">
                  <div className="col">
                    <div>
                      <h1 style={styles.missionTitle}>Our Mission is to provide 1 million meals through our patronage of Union Gospel Mission - Twin Cities and Feeding America.&nbsp;</h1>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div style={{ textAlign: 'center' }}>
                      <img className="img-thumbnail img-fluid" src="assets/img/General_Lee_no_bg.png" style={styles.sauceImage} alt="Rasta Tease Bottle of Hot Sauce" />
                      {/* <img className="img-thumbnail img-fluid" src="assets/img/General_Lee_white_bg.png" style={styles.sauceImage} alt="Rasta Tease Bottle of Hot Sauce" /> */}
                    </div>
                  </div>
                  <div className="col">
                    <div>
                      <p style={styles.missionBody}>
                        We want you to enjoy our sauce, but wouldn't it be even better if someone got a meal too? This is what we are doing.
                  <br />
                        {/* <span>1 in 4 children go hungry everyday - we can change this.</span> */}
                  &nbsp;
                  <br />
                        <strong>
                          You buy the sauce, we buy the meals.
                          &nbsp;
                    <br />
                    The more we help, the better we feel.
                  </strong>
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col" style={styles.missionFooter}>
                    <div>
                      <h1 style={styles.missionFooterHeading}>Support Our Mission Today!</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )
        }
      </Spring>
    </div>
  )
}

const styles = {
  missionContainer: {
    marginTop: '80px',
    marginBottom: 'max("10vw", "60px")',
    padding: '24px',
  },
  missionTitle: {
    color: 'var(--bs-light)',
    textAlign: 'center',
    marginBottom: '24px',
    backgroundColor: 'var(--bs-red)',
    padding: '10px',
    fontWeight: 800
  },
  sauceImage: {
    maxWidth: '100%',
    // minWidth: '300px',
    maxHeight: '300px',
    border: 'none'
  },
  missionBody: {
    textAlign: 'left',
    fontSize: '30px',
    lineHeight: '1em',
    marginTop: '16px',
  },
  missionFooter: {
    minWidth: '100%',
  },
  missionFooterHeading: {
    color: 'var(--bs-red)',
    textAlign: 'center',
    marginTop: '32px',
  }
}