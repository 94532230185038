import React from "react";

export default function Footer() {
  return (
    <div>
      <footer className="footer-basic">
        <div className="social">
          <a href="https://www.instagram.com/rastatease0826/">
            <i className="icon ion-social-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/rastatease">
            <i className="icon ion-social-linkedin"></i>
          </a>
          <a href="https://youtube.com/rastatease">
            <i className="icon ion-social-youtube"></i>
          </a>
          <a href="https://www.facebook.com/rastatease">
            <i className="icon ion-social-facebook"></i>
          </a>
          <a href="mailto:peeweedread@rastatease.com">
            <i className="icon ion-email"></i>
          </a>
        </div>
        <p className="copyright">Rasta Tease © {new Date().getFullYear()}</p>
      </footer>
    </div>
  );
}

// function Copyright() {
//   return (
//     <div className="copyright">
//       <div href="">
//         {'Copyright © '}{'Rasta Tease'} {new Date().getFullYear()}{'.'}
//       </div>
//     </div>
//   );
// }

// export default function Footer() {
//   return (
//     <div className="container" id="contact">
//       <div className="text-center center-block">
//         <a href="https://www.facebook.com/rastatease"><i id="social-fb" className="fa fa-facebook-square fa-3x social"></i></a>
//         <a href="https://twitter.com/RastaTease"><i id="social-tw" className="fa fa-twitter-square fa-3x social"></i></a>
//         <a href="https://www.instagram.com/rastatease0826/"><i id="social-gp" className="fa fa-instagram fa-3x social"></i></a>
//         {/* <a href="https://plus.google.com/rastatease"><i id="social-gp" className="fa fa-google-plus-square fa-3x social"></i></a> */}
//         <a href="mailto:management@rastatease.com"><i id="social-em" className="fa fa-envelope-square fa-3x social"></i></a>
//       </div>
//       <Copyright />
//     </div>

//   )
// }
