import React, { useState } from "react";

// Stripe
import { loadStripe } from "@stripe/stripe-js";

// Animation
import { Spring } from "react-spring/renderprops";
import axios from "axios";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_LIVE_STRIPE_KEY);

export default function ProductCard() {
  let [bottleQuantity, setBottleQuantity] = useState(0);
  let [processingTransaction, setProcessingTransaction] = useState(false);
  let [purchaseError, setPurchaseError] = useState(false);
  // let [processingTransaction, setProcessingTransaction] = useState(false);

  // Fetch Stripe Checkout Session
  const fetchCheckoutSession = async ({ quantity }, stripe) => {
    // let lambdaURL = "https://4n6xfirdn5.execute-api.us-east-1.amazonaws.com/dev/purchase/create-checkout-session";
    let firebaseURL =
      "https://us-central1-rt-p-9fc05.cloudfunctions.net/payment/purchase/create-checkout-session";

    return axios
      .post(firebaseURL, { quantity })
      .then(function (response) {
        console.log("RES_ ", response);
        return response.data;
      })
      .then(function (session) {
        console.log("Session ", session);
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .catch(function (error) {
        console.log("Error reaching payment endpoint: ", error);
        setPurchaseError(true);
        setProcessingTransaction(false);
      });
  };

  const submitPaymentRequest = async (event) => {
    // Check if bottle quantity does not equal 0
    if (bottleQuantity === 0) {
      return;
    }

    setProcessingTransaction(true);
    setPurchaseError(false);

    const stripe = await stripePromise;

    await fetchCheckoutSession({ quantity: bottleQuantity }, stripe);
  };

  // Change quantity of hot sauce
  function changeQuantity(direction) {
    let newQuantity;
    if (direction === "down") {
      newQuantity = bottleQuantity === 0 ? bottleQuantity : bottleQuantity - 1;
    } else {
      newQuantity = bottleQuantity + 1;
    }
    setBottleQuantity(newQuantity);
  }

  return (
    <div>
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 1500 }}
      >
        {(props) => (
          <div style={props}>
            <section id="product-section" style={styles.productSectionElem}>
              <div
                className="container product-container"
                style={styles.productContainer}
              >
                <div style={styles.productHeader}>
                  <h1
                    className="text-center product-card"
                    style={styles.productHeaderTitle}
                  >
                    Rasta Tease Original
                  </h1>
                  <h4 style={styles.productHeaderSubtitle}>
                    Purchase a bottle of Rasta Tease!
                  </h4>
                  <div className="gl-image">
                    <img
                      id="general_lee_img"
                      src="assets/img/General_Lee_no_bg.png"
                      alt="General Lee Rasta Tease"
                    />
                  </div>
                </div>
                {/* <div className="quantity-setter" style={styles.quantitySetter}>
                  <button
                    className="btn btn-primary quantity-btn"
                    type="button"
                    style={styles.quantityDownBtn}
                    disabled={processingTransaction}
                    onClick={() => changeQuantity("down")}
                  >
                    <i className="far fa-arrow-alt-circle-down"></i>
                  </button>
                  <input
                    type="number"
                    className="quantity-input"
                    value={bottleQuantity}
                    name="Quantity"
                    min="0"
                    onChange={() => {}}
                  />
                  <button
                    className="btn btn-primary quantity-btn"
                    type="button"
                    style={styles.quantityUpBtn}
                    disabled={processingTransaction}
                    onClick={() => changeQuantity("up")}
                  >
                    <i className="far fa-arrow-alt-circle-up"></i>
                  </button>
                </div> */}
                {/* <button
                  className="btn btn-primary"
                  id="submit-order-btn"
                  type="button"
                  style={styles.productBuyBtn}
                  disabled={processingTransaction}
                  onClick={() => submitPaymentRequest()}
                >
                  Buy {bottleQuantity !== 0 ? `$${bottleQuantity * 10.0}` : " "}
                </button> */}

                <div style={styles.stripeBuyBtn}>
                  <stripe-buy-button
                    buy-button-id="buy_btn_1OzPwhD78JfmJoaqqYciFr2a"
                    publishable-key="pk_live_J2k5dTEzzMZEOV1DwZ2KpbWE"
                  ></stripe-buy-button>
                </div>

                {purchaseError && (
                  <h4 style={{ color: "red", textAlign: "center" }}>
                    There was an error, please try again later!
                  </h4>
                )}
              </div>
            </section>
          </div>
        )}
      </Spring>
    </div>
  );
}

const styles = {
  productSectionElem: {
    background: "rgba(255,255,2555,0.75)",
    padding: "50px",
    paddingRight: "20px",
    paddingLeft: "20px",
    height: "100%",
  },
  productContainer: {
    padding: "20px",
    paddingBottom: "30px",
    paddingTop: "30px",
  },
  productHeader: {
    // maxHeight: 30,
  },
  productHeaderTitle: {
    color: "var(--bs-red)",
  },
  productHeaderSubtitle: {
    textAlign: "center",
  },
  quantitySetter: {
    marginTop: "10px",
    padding: "10px",
  },
  quantityDownBtn: {
    marginLeft: "10px",
    borderStyle: "none",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "var(--bs-red)",
  },
  quantityUpBtn: {
    marginRight: "10px",
    borderStyle: "none",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "var(--bs-red)",
  },
  productBuyBtn: {
    marginTop: "20px",
    width: "100%",
    borderStyle: "none",
    color: "var(--bs-light)",
    textAlign: "center",
    fontWeight: 800,
    fontSize: "30px",
  },
  stripeBuyBtn: {
    padding: "20px",
  },
};
