// import './App.css';
import NavBar from './components/NavBar';
import MillionMealMarchHeader from './components/MillionMealMarchHeader';
import ProductCard from './components/ProductCard';
// import EmailForm from './components/EmailForm';
import FoodStory from './components/FoodStory';
import MillionMealMarchMission from './components/MillionMealMarchMission';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar />
      <MillionMealMarchHeader />
      <ProductCard />
      {/* <EmailForm /> */}
      <FoodStory />
      <MillionMealMarchMission />
      <Footer />
    </div>
  );
}

export default App;
