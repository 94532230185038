import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// AWS imports
// import Amplify from 'aws-amplify';
// import awsconfig from './aws-exports'
// let awsconfig = process.env.REACT_APP_SECRET_FILE;

require('dotenv').config()

// Amplify.configure(awsconfig)
// // API.configure(awsconfig)

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
